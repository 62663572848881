require.resolve("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./en.lyaml?sporks");

export default {
  mode: "lazy",
  source: "cms-free-branding-lib/lang",
  context: {
    "af": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./af.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-af" */); },
    "ar-eg": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./ar-eg.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-ar-eg" */); },
    "bg": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./bg.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-bg" */); },
    "bn": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./bn.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-bn" */); },
    "ca-es": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./ca-es.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-ca-es" */); },
    "cs": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./cs.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-cs" */); },
    "da": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./da.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-da" */); },
    "de": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./de.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-de" */); },
    "el": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./el.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-el" */); },
    "en-gb": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./en-gb.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-en-gb" */); },
    "en": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./en.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-en" */); },
    "es-mx": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./es-mx.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-es-mx" */); },
    "es": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./es.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-es" */); },
    "fi": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./fi.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-fi" */); },
    "fr-ca": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./fr-ca.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-fr-ca" */); },
    "fr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./fr.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-fr" */); },
    "he-il": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./he-il.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-he-il" */); },
    "hr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./hr.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-hr" */); },
    "hu": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./hu.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-hu" */); },
    "id": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./id.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-id" */); },
    "it": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./it.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-it" */); },
    "ja": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./ja.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-ja" */); },
    "ko": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./ko.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-ko" */); },
    "lt": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./lt.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-lt" */); },
    "ms": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./ms.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-ms" */); },
    "nl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./nl.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-nl" */); },
    "no": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./no.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-no" */); },
    "pl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./pl.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-pl" */); },
    "pt-br": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./pt-br.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-pt-br" */); },
    "pt-pt": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./pt-pt.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-pt-pt" */); },
    "ro": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./ro.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-ro" */); },
    "ru": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./ru.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-ru" */); },
    "sk": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./sk.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-sk" */); },
    "sl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./sl.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-sl" */); },
    "sv": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./sv.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-sv" */); },
    "th": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./th.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-th" */); },
    "tl": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./tl.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-tl" */); },
    "tr": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./tr.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-tr" */); },
    "uk": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./uk.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-uk" */); },
    "vi": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./vi.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-vi" */); },
    "zh-cn": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./zh-cn.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-zh-cn" */); },
    "zh-hk": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./zh-hk.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-zh-hk" */); },
    "zh-tw": function () { return import("!/usr/share/hubspot/build/.bpm/packages/bender-build-tools/static-1.608/node_modules/bender-lyaml-loader/lib/modern.js??ref--17!./zh-tw.lyaml?sporks" /* webpackChunkName: "cms-free-branding-lib-lang-zh-tw" */); }  }
};
