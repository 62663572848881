export const onReady = fn => {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};
export const toggleClassName = (el, className) => el.classList.contains(className) ? el.classList.remove(className) : el.classList.add(className);
export const toggleARIABooleanAttribute = (el, attributeName) => {
  const isTrue = el.getAttribute(attributeName) === 'true';
  return el.setAttribute(attributeName, isTrue ? 'false' : 'true');
};
export const attachStylesheet = href => {
  const link = document.createElement('link');
  link.href = href;
  link.rel = 'stylesheet';
  document.body.appendChild(link);
};
export const parseHtmlElementFromString = htmlString => new DOMParser().parseFromString(htmlString.trim(), 'text/html').body.firstChild;
export const newlineRegex = /\r?\n|\r/g;
export const makeInlineStyle = inlineCssString => inlineCssString.replace(newlineRegex, '').trim();