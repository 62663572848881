// I18n setup:
// @ts-expect-error I18n doesn't have types yet
import createPublicI18nProvider from 'I18n/init/providers/createPublicI18nProvider';
import lang from 'i2l?query=sporks!../lang/en.lyaml'; // @ts-expect-error I18n doesn't have types yet

import registerAllData from 'I18n/init/register/sync/registerAllData';
import { getBrandingHtmlElement } from './html/brandingHtmlElement'; // @ts-expect-error bender-url isn't supported in TypeScript yet

import hsCmsFreeBrandingStyles from '!bender-url!../css/hsCmsFreeBranding.css';
import { attachStylesheet, onReady, toggleARIABooleanAttribute, toggleClassName } from './utils/domHelpers';
const collapsedClassName = 'hs-cmsf-branding__dropdown--collapsed';

const insertBranding = () => {
  const brandingHtmlElement = getBrandingHtmlElement();
  attachStylesheet(hsCmsFreeBrandingStyles);
  document.body.appendChild(brandingHtmlElement);
};

const initBranding = () => {
  const brandingWidget = document.getElementById('hs-cmsf-branding');
  const brandingWidgetButton = document.getElementsByClassName('hs-cmsf-branding__button')[0];
  const brandingWidgetDropdown = document.getElementById('hs-cmsf-branding__dropdown');
  const isBrandingInserted = brandingWidget && brandingWidgetButton && brandingWidgetDropdown;

  if (!isBrandingInserted) {
    return;
  }

  document.addEventListener('click', ({
    target
  }) => {
    if (!(target instanceof HTMLElement)) {
      return; // context: https://github.com/Microsoft/TypeScript/issues/29540
    }

    if (brandingWidget.contains(target)) {
      toggleClassName(brandingWidgetDropdown, collapsedClassName);
      toggleARIABooleanAttribute(brandingWidgetButton, 'aria-expanded');
    } else {
      brandingWidgetDropdown.classList.add(collapsedClassName);
      brandingWidgetButton.setAttribute('aria-expanded', 'false');
    }
  });
};

const start = () => {
  if (!window.hsInEditor) {
    insertBranding();
    initBranding();
  }
};

const I18nPublicProvider = createPublicI18nProvider();
const langAvailable = Promise.all([I18nPublicProvider.register(lang), registerAllData(I18nPublicProvider)]);
langAvailable.then(() => onReady(start));