import enviro from 'enviro';
import { getGlobal } from './getGlobal';

// need to support the React Native globalThis
let _customSendBeaconFn;

function getHubSpot() {
  const _global = getGlobal();

  return typeof _global === 'object' ? _global.hubspot : undefined;
}

export function _sendBeacon(url, data) {
  const global = getGlobal();

  if (_customSendBeaconFn) {
    return _customSendBeaconFn(url, data);
  }

  return global.navigator && global.navigator.sendBeacon(url, data);
}

function sendBeacon(url, data = '') {
  if (!_sendBeacon) {
    return;
  }

  try {
    _sendBeacon(url, data);
  } catch (___err) {// drop errors
  }
}

export function setBeaconApi(beaconFn) {
  _customSendBeaconFn = beaconFn;
}
const staticAppInfo = {
  package: getHubSpot() && getHubSpot().bender && getHubSpot().bender.currentProject || 'unknown',
  version: getHubSpot() && getHubSpot().bender && getHubSpot().bender.currentProjectVersion || 'unknown'
};
export function setStaticAppInfo(newInfo) {
  Object.assign(staticAppInfo, newInfo);
}

function getMetricsUrl() {
  return `https://api${enviro.getHublet() === 'na1' ? '' : `-${enviro.getHublet()}`}.hubspot${enviro.isQa() ? 'qa' : ''}.com/metrics/v1/frontend/custom/send?hs_static_app=${staticAppInfo.package}&hs_static_app_version=${staticAppInfo.version}`;
}

export function send(metricReports) {
  if (!enviro.deployed('METRICS')) {
    if (enviro.debug('METRICS')) {
      console.log('[metrics-js] Dropping local datapoint', metricReports);
    }

    return;
  }

  if (enviro.debug('METRICS')) {
    console.log('[metrics-js] Datapoint sent', metricReports);
  }

  sendBeacon(getMetricsUrl(), JSON.stringify(metricReports));
}